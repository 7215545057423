import React from 'react';

import { graphql } from "gatsby"

import HeroSection from './shared/HeroSection';

import OutOfTheBoxContent from '../Elements/OutOfTheBoxContent';
import TrustedBrands from '../Elements/TrustedBrands';

export default (props) => (
  <>
    <HeroSection {...props} />
    <TrustedBrands className="pb-64 pt-40 md:pt-24" {...props.slice.primary} />
    <OutOfTheBoxContent className="-mt-48" />
  </>
);

export const query = graphql`
  fragment ComparisonPageV2HeroSlice on PrismicComparisonPageV2DataBodyHero {
    id
    slice_type
    primary {
      brief
      title {
        richText
        text
      }
      description {
        richText
      }
      hero_image
      button_text: trusted_brands_button_text
      button_link: trusted_brands_button_link
    }
  }
`;
