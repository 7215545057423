import React from 'react';
import { kebabCase, uniqBy } from 'lodash';

import RichOrText from './RichOrText';

const deriveRowKey = (text) => kebabCase(text).toLowerCase();

const commonBorder = "border border-solid border-[#B9B9B9]";
const commonCellProps = "px-[3.3rem] py-8 min-w-[300px]";

const ComparisonTable = ({ className, cols = [] }) => {
  const fields = uniqBy(
    cols.flatMap((col) => col.properties.map(({ field }) => [deriveRowKey(field), field])),
    ([key]) => key,
  );
  return (
    <div
      className={[
        `${commonBorder} w-full bg-white rounded-2xl overflow-y-hidden overflow-x-scroll mx-auto block`,
        className,
      ].filter(Boolean).join(' ')}
      style={{ maxWidth: `${(cols.length + 1) * 360}px` }}
    >
      <table className="w-full border-collapse border-hidden">
        <thead>
          <tr>
            {[{}, ...cols].map(({ image, label }) => (
              <th className={`p-8 ${commonBorder}`}>
                <div className="flex items-center justify-center h-[80px]">
                  {image && (
                    <img
                      alt={label}
                      className="m-0 max-h-[40px] h-auto w-auto"
                      loading="lazy"
                      src={image?.fluid?.srcWebp}
                      srcset={image?.fluid?.srcSetWebp}
                    />
                  )}
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {fields.map(([key, field]) => (
            <tr key={key}>
              <td className={`${commonBorder} ${commonCellProps} text-center`}>
                <h3 className="text-black font-inter font-bold whitespace-nowrap">
                  {field}
                </h3>
              </td>
              {cols.map((col) => {
                const {
                  text_align = 'left',
                  value,
                } = col.properties.find((row) => deriveRowKey(row.field) === key) || {};
                return (
                  <td className={`${commonBorder} ${commonCellProps}`}>
                    <div className={`text-${text_align}`}>
                      {value ? (
                        <RichOrText
                          content={value?.richText || value}
                          components={{
                            paragraph: ({ children }) => (
                              <p className="m-0">
                                {children}
                              </p>
                            ),
                          }}
                        />
                      ) : '-'}
                    </div>
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ComparisonTable;
