import React from 'react';

import { graphql, useStaticQuery } from 'gatsby';

import ComparisonTable from '../Elements/ComparisonTable';
import StripeSection from '../Elements/StripeSection';

const Summary = ({
  context,
  slice: {
    primary: {
      heading,
      description,
    },
  },
}) => {
  const { allPrismicComparisonPageV2: pages } = useStaticQuery(graphql`
    query {
      allPrismicComparisonPageV2(filter: {uid: {}, id: {}}) {
        edges {
          node {
            id
            data {
              body {
                ... on PrismicComparisonPageV2DataBodyComparisonTableColumn {
                  id
                  slice_type
                  primary {
                    label
                    image {
                      dimensions {
                        width
                        height
                      }
                      fluid {
                        srcSetWebp
                        srcWebp
                      }
                    }
                  }
                  items {
                    field
                    value {
                      richText
                    }
                    text_align
                  }
                }
              }
            }
          }
        }
      }
    }
  `);
  const cols = pages
    .edges
    .find(({ node }) => node.id === context.id)
    .node
    .data
    .body
    .filter(({ slice_type }) => slice_type === 'comparison_table_column')
    .map(({ items, primary }) => ({
      ...primary,
      properties: items,
    }));
  return (
    <StripeSection title={heading} description={description}>
      <ComparisonTable className="mt-16 mb-32" cols={cols} />
    </StripeSection>
  );
};

export default Summary;

export const query = graphql`
  fragment ComparisonPageV2SummarySlice on PrismicComparisonPageV2DataBodySummary {
    id
    slice_type
    primary {
      heading
      description {
        richText
      }
    }
  }
`;
