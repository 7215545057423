import { graphql } from "gatsby"

import SalesSection from './shared/SalesSection';

export default SalesSection;

export const query = graphql`
  fragment ComparisonPageV2SalesSlice on PrismicComparisonPageV2DataBodySales {
    id
    slice_type
    primary {
      section_title
      button_text
      button_link
    }
  }
`;
