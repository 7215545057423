import HeroSection from './HeroSection';
import SalesSection from './SalesSection';
import Summary from './Summary';
import Details from './Details';

export const components = {
  hero: HeroSection,
  sales: SalesSection,
  summary: Summary,
  details: Details,
};
