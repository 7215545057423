import React from 'react';

import { graphql } from 'gatsby';

import LinearContentBlock from '../Elements/LinearContentBlock';
import StripeSection from '../Elements/StripeSection';

const Details = ({
  slice: {
    items,
    primary: {
      heading,
      description,
    },
  },
}) => (
  <div className="relative w-full">
    <div
      className="bg8 hidden lg:block -right-20 top-16 absolute"
      style={{ transform: 'scale(0.75) rotate(120deg)' }}
    />
    <div
      className="bg8 hidden lg:block left-0 top-[1200px] absolute"
      style={{ transform: 'scale(0.5) rotate(168deg)' }}
    />
    <div
      className="bg8 hidden lg:block right-0 top-[2560px] absolute"
      style={{ transform: 'rotate(160deg)' }}
    />
    <div
      className="bg8 hidden lg:block -left-20 top-[4420px] absolute"
      style={{ transform: 'scale(0.75) rotate(102deg)' }}
    />
    <div
      className="bg8 hidden lg:block right-0 top-[5420px] absolute"
      style={{ transform: 'scale(0.5) rotate(100deg)' }}
    />
    <StripeSection className="z-10" title={heading} description={description}>
      <LinearContentBlock
        entries={items.map(({ heading: title, description: content, ...rest }) => ({
          ...rest, title, content,
        }))}
        orient="vertical"
      />
    </StripeSection>
  </div>
);

export default Details;

export const query = graphql`
  fragment ComparisonPageV2DetailsSlice on PrismicComparisonPageV2DataBodyDetails {
    id
    slice_type
    primary {
      heading
      description {
        richText
      }
    }
    items {
      heading
      description {
        richText
      }
      image {
        dimensions {
          width
          height
        }
        fluid {
          srcSetWebp
          srcWebp
        }
      }
      button_text
      button_link
    }
  }
`;
