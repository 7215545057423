import React from "react";
import { graphql } from "gatsby";

import { SliceZone } from "@prismicio/react"
import { components } from "../components/ComparisonPageV2"
import SEO from "../components/seo"
import Layout from "../components/layout"

const ComparisonPageV2 = (props) => {
  const { data } = props
  if (!data) return null
  const pageData = data.prismicComparisonPageV2
  const page = pageData.data || {}
  return (
    <Layout className="background1">
      <SEO
        context={props.pageContext}
        cpath={props.location.pathname}
      />
      <SliceZone
        slices={page.body}
        components={components}
        context={pageData}
        defaultComponent={() => null}
      />
    </Layout>
  );
};

export default ComparisonPageV2;

export const query = graphql`
  query comparisonPageV2Query ($uid: String!){
    prismicComparisonPageV2 (uid: {eq: $uid}) {
      id
      type
      data {
        body{
          ... on PrismicSliceType {
            slice_type
          }
          ...ComparisonPageV2HeroSlice
          ...ComparisonPageV2SalesSlice
          ...ComparisonPageV2SummarySlice
          ...ComparisonPageV2DetailsSlice
        }
      }
    }
  }
`
